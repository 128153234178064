import * as i0 from '@angular/core';
import { Injectable, Component, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { tap, finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
function SpinnerComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵelement(3, "div", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
}
class SpinnerService {
  constructor() {
    this.pSpinnerVisible = new BehaviorSubject(false);
    this.spinnerVisible = this.pSpinnerVisible.asObservable();
    this.pSpinnerInterceptorOn = new BehaviorSubject(true);
    this.spinnerInterceptorOn = this.pSpinnerInterceptorOn.asObservable();
  }
  show() {
    this.pSpinnerVisible.next(true);
  }
  hide() {
    this.pSpinnerVisible.next(false);
  }
  activateInterceptor() {
    this.pSpinnerInterceptorOn.next(true);
  }
  deactivateInterceptor() {
    this.pSpinnerInterceptorOn.next(false);
  }
  static {
    this.ɵfac = function SpinnerService_Factory(t) {
      return new (t || SpinnerService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SpinnerService,
      factory: SpinnerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class SpinnerComponent {
  constructor(spinnerService) {
    this.spinnerService = spinnerService;
  }
  ngOnInit() {
    this.spinnerService.spinnerVisible.pipe(tap(visible => {
      this.visible = visible;
    })).subscribe();
  }
  static {
    this.ɵfac = function SpinnerComponent_Factory(t) {
      return new (t || SpinnerComponent)(i0.ɵɵdirectiveInject(SpinnerService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SpinnerComponent,
      selectors: [["wilson-spinner"]],
      decls: 1,
      vars: 1,
      consts: [[4, "ngIf"], [1, "wlt-spinner-overlay"], [1, "wlt-spinner"], [1, "lds-dual-ring"]],
      template: function SpinnerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, SpinnerComponent_ng_container_0_Template, 4, 0, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.visible);
        }
      },
      dependencies: [i2.NgIf],
      styles: [".wlt-spinner-overlay[_ngcontent-%COMP%]{position:fixed;top:0;left:0;width:100%;height:100%;z-index:99;background:white;opacity:.9}.wlt-spinner[_ngcontent-%COMP%]{position:fixed;inset:15% 0 0;z-index:99;text-align:center}.wlt-spinner[_ngcontent-%COMP%]   .lds-dual-ring[_ngcontent-%COMP%]{display:inline-block;width:121px;height:121px}.wlt-spinner[_ngcontent-%COMP%]   .lds-dual-ring[_ngcontent-%COMP%]:after{content:\" \";display:block;width:121px;height:121px;margin:8px;border-radius:50%;border:10px solid #000;border-color:#000 transparent #000 transparent;animation:_ngcontent-%COMP%_lds-dual-ring 1.2s linear infinite}@keyframes _ngcontent-%COMP%_lds-dual-ring{0%{transform:rotate(0)}to{transform:rotate(360deg)}}"]
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerComponent, [{
    type: Component,
    args: [{
      selector: 'wilson-spinner',
      template: "<ng-container *ngIf=\"visible\">\r\n  <div class=\"wlt-spinner-overlay\"></div>\r\n  <div class=\"wlt-spinner\">\r\n    <div class=\"lds-dual-ring\"></div>\r\n  </div>\r\n</ng-container>\r\n",
      styles: [".wlt-spinner-overlay{position:fixed;top:0;left:0;width:100%;height:100%;z-index:99;background:white;opacity:.9}.wlt-spinner{position:fixed;inset:15% 0 0;z-index:99;text-align:center}.wlt-spinner .lds-dual-ring{display:inline-block;width:121px;height:121px}.wlt-spinner .lds-dual-ring:after{content:\" \";display:block;width:121px;height:121px;margin:8px;border-radius:50%;border:10px solid #000;border-color:#000 transparent #000 transparent;animation:lds-dual-ring 1.2s linear infinite}@keyframes lds-dual-ring{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"]
    }]
  }], function () {
    return [{
      type: SpinnerService
    }];
  }, null);
})();
class SpinnerInterceptor {
  constructor(spinner) {
    this.spinner = spinner;
  }
  intercept(req, next) {
    let finished = false;
    setTimeout(() => {
      if (!finished && this.spinner.pSpinnerInterceptorOn.value === true) {
        this.spinner.show();
      }
    }, 500);
    return next.handle(req).pipe(finalize(() => {
      finished = true;
      if (this.spinner.pSpinnerInterceptorOn.value === true) {
        this.spinner.hide();
      }
    }));
  }
  static {
    this.ɵfac = function SpinnerInterceptor_Factory(t) {
      return new (t || SpinnerInterceptor)(i0.ɵɵinject(SpinnerService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SpinnerInterceptor,
      factory: SpinnerInterceptor.ɵfac
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerInterceptor, [{
    type: Injectable
  }], function () {
    return [{
      type: SpinnerService
    }];
  }, null);
})();
class SpinnerModule {
  static {
    this.ɵfac = function SpinnerModule_Factory(t) {
      return new (t || SpinnerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SpinnerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: SpinnerInterceptor,
        multi: true
      }],
      imports: [CommonModule]
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerModule, [{
    type: NgModule,
    args: [{
      declarations: [SpinnerComponent],
      imports: [CommonModule],
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: SpinnerInterceptor,
        multi: true
      }],
      exports: [SpinnerComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SpinnerComponent, SpinnerInterceptor, SpinnerModule, SpinnerService };
